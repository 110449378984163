import { environment } from "./environments/environment";

function init(currentScript: any): void {
  if (!currentScript) return;
  const license = currentScript.getAttribute("l");
  const useStapler = window.location !== window.parent.location && window.location.ancestorOrigins[0] === environment.host
  const url = `${environment.host}/${
    useStapler ? "stapler" : "highlightra"
  }.js`;
  const y = document.createElement("script");
  y.async = !0;
  y.src = url;
  y.setAttribute("l", license);
  const z = <any>document.getElementsByTagName("script")[0];
  z.parentNode.insertBefore(y, z);
}

const currentScript = document.currentScript;
init(currentScript);
